import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <g fill="#129D51">
        <path d="M51.14 16H37.732a9.712 9.712 0 002.265-6.242C39.997 4.378 35.62 0 30.24 0s-9.758 4.377-9.758 9.758a9.71 9.71 0 002.265 6.241H9.339L1 60h58.479L51.14 16zM30.24 3.515c3.441 0 6.241 2.8 6.241 6.242 0 3.441-2.8 6.241-6.241 6.241a6.249 6.249 0 01-6.242-6.241 6.25 6.25 0 016.242-6.242zm-17.99 16h35.978l7.006 36.97H5.244l7.006-36.97z" />
        <path d="M30.31 31.007h-3.99l-5.578 5.57v-5.57h-2.868v14.05h2.868v-4.803l1.446-1.41 4.37 6.212h3.701l-6.024-8.182z" />
        <path d="M35.984 40.002h3.789v1.54c-.305.223-.72.44-1.237.65a5.164 5.164 0 01-1.945.384 4.795 4.795 0 01-2.15-.502 3.2 3.2 0 01-1.469-1.468c-.352-.687-.53-1.568-.53-2.618a6.2 6.2 0 01.448-2.37c.167-.4.406-.773.709-1.11.289-.32.682-.585 1.167-.788.495-.207 1.1-.311 1.799-.311.569 0 1.09.098 1.55.292.436.184.77.427.995.72.242.316.452.773.625 1.357l.16.547 2.64-.724-.142-.553c-.242-.943-.606-1.726-1.083-2.327-.49-.616-1.166-1.1-2.01-1.44-.82-.327-1.742-.494-2.744-.494-1.374 0-2.611.29-3.676.86-1.09.584-1.937 1.486-2.513 2.682a8.585 8.585 0 00-.845 3.764c0 1.353.288 2.6.856 3.703a5.966 5.966 0 002.59 2.6c1.114.584 2.365.88 3.72.88a8.299 8.299 0 002.948-.543c.944-.356 1.869-.894 2.75-1.597l.22-.174v-5.644l-6.622.01v2.674z" />
      </g>
    </svg>
  )
}

export default SvgComponent
import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        fill="#129D51"
        d="M34.571.539c.519-.647 1.49-.711 2.139-.194.518.453.648 1.164.453 1.746l-7.711 22.056 20.348.064c.842 0 1.555.712 1.555 1.553 0 .323-.13.646-.324.905-5.703 7.373-11.73 14.617-17.756 21.861-2.98 3.558-5.962 7.115-9.072 10.931-.519.647-1.49.711-2.139.194-.518-.453-.648-1.164-.453-1.746l7.711-22.056-20.348-.064c-.842 0-1.49-.647-1.49-1.488 0-.388.13-.711.324-.97l17.885-21.926A1307.81 1307.81 0 0134.571.539zm6.245 5.384c.39-.71 1.367-.97 2.084-.582 4.427 2.392 8.008 5.948 10.483 10.085 2.474 4.138 3.841 8.921 3.776 13.834a27.196 27.196 0 01-6.12 17.196c-3.842 4.784-9.246 8.21-15.562 9.632-.846.194-1.628-.323-1.823-1.163-.195-.84.325-1.616 1.172-1.81a24.407 24.407 0 0013.869-8.534c3.385-4.202 5.404-9.503 5.404-15.256 0-4.46-1.172-8.727-3.386-12.412a24.616 24.616 0 00-9.31-8.921c-.717-.388-.978-1.358-.587-2.069zm-17.45-2.98c.78-.193 1.624.323 1.819 1.162.195.773-.325 1.612-1.169 1.805-5.585 1.29-10.326 4.385-13.638 8.577a23.89 23.89 0 00-5.326 15.089c0 4.45 1.234 8.77 3.442 12.445 2.273 3.74 5.456 6.836 9.417 8.964.78.387 1.04 1.29.65 2.063-.39.774-1.3 1.032-2.079.645-4.48-2.386-8.053-5.868-10.585-10.06A27.118 27.118 0 012 29.577a27.17 27.17 0 015.975-17.024c3.767-4.707 9.027-8.19 15.392-9.608zm8.095 6.328l-3.37 4.139c-5.314 6.468-10.563 12.936-15.877 19.404l19.182.064c.194 0 .389 0 .583.065.842.259 1.231 1.164.972 1.94l-5.573 15.911c1.167-1.423 2.398-2.846 3.564-4.269 5.379-6.403 10.693-12.806 15.812-19.339l-19.311-.064c-.195 0-.389 0-.583-.065-.843-.259-1.232-1.164-.972-1.94L31.46 9.27z"
      />
    </svg>
  )
}

export default SvgComponent
import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        fill="#129D51"
        d="M30 4a1.507 1.507 0 01.907.303l.023.017.016.013.02.016.08.072.014.014 4 3.965c.585.58.585 1.521 0 2.101a1.51 1.51 0 01-2.007.102l-.114-.102L31.5 9.074v5.316l18.61 7.423.057.027.14.079.113.08.088.075.085.084.078.09.071.097a1.464 1.464 0 01.25.83l-.001 21.587 3.629-1.413-1.894-.835a1.481 1.481 0 01-.76-1.963 1.504 1.504 0 011.845-.805l.134.052 5.166 2.28.034.016.045.022-.079-.038a1.52 1.52 0 01.381.242l.007.006.017.015a1.483 1.483 0 01.082.08l.025.028a1.483 1.483 0 01.274.443l.013.034.022.064-.035-.098a1.491 1.491 0 01.085.777 1.656 1.656 0 01-.059.238l-.008.024a1.28 1.28 0 01-.034.086l-.01.023-2.3 5.12a1.505 1.505 0 01-1.98.754 1.482 1.482 0 01-.812-1.829l.053-.133.81-1.807-21.681 8.443c-.262 1.864-1.831 3.313-3.76 3.407L30 58c-2.02 0-3.69-1.484-3.96-3.412L4.358 46.145l.811 1.807.053.133c.243.718-.1 1.515-.812 1.829a1.505 1.505 0 01-1.98-.753l-2.3-5.12-.01-.024a1.487 1.487 0 01-.034-.086l-.008-.024a1.482 1.482 0 01-.032-.837c.016-.06.035-.119.058-.178l-.035.098.022-.064.013-.034a1.509 1.509 0 01.21-.366l.012-.016.011-.013.041-.049a1.481 1.481 0 01.512-.37l-.08.038.046-.022.034-.016 5.166-2.28.134-.052c.724-.24 1.528.1 1.845.805a1.481 1.481 0 01-.76 1.963l-1.895.835 3.628 1.413V23.167c0-.308.094-.59.25-.822l.071-.097.078-.09.085-.084.088-.075.114-.08.14-.08.056-.026L28.5 14.39V9.074L27.062 10.5l-.114.102a1.51 1.51 0 01-2.007-.102 1.477 1.477 0 010-2.101l3.999-3.964.018-.017c.025-.024.05-.047.077-.07l.019-.015.016-.013a1.504 1.504 0 01.105-.075l.024-.016a1.49 1.49 0 01.005-.003l.099-.056A1.495 1.495 0 0130 4zm17.992 21.228l-16.493 5.45v19.684a4.006 4.006 0 011.718 1.32l14.775-5.753v-20.7zm-35.985 0V45.93l14.776 5.753a4.006 4.006 0 011.717-1.32V30.678l-16.493-5.45zM28.5 17.594l-13.647 5.442L28.5 27.545v-9.95zm2.999-.001v9.951l13.647-4.508L31.5 17.593z"
      />
    </svg>
  )
}

export default SvgComponent
